@import 'styles/colors';

$root-element: 'awssld';
$transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
$slider-transition-duration: 575ms;

.#{$root-element} {
  &--scaleOutAnimation {
    --scale-out-scale: 1;

    .#{$root-element} {
      &--exit {
        &.#{$root-element}--moveRight,
        &.#{$root-element}--moveLeft {
          animation: scaleOut var(--slider-transition-duration) both var(--transition-bezier);
        }
      }
    }
  }
}

@keyframes scaleOut {
  to {
    transform: scale(var(--scale-out-scale));
  }
}

.#{$root-element} {
  &__timer {
    opacity: 0;
    pointer-events: none; /* prevent events (mouse clicking etc.) */
  }

  &__content {
    background-color: rgba(0, 0, 0, 0);
    & > div {
      height: 100%;
      width: 100%;
    }
  }

  &__bullets {
    z-index: 2;
    bottom: 5%;
    padding: 0 5%;

    &--active {
      background-color: #fff !important;
      transform: none !important;
    }

    & button {
      background-color: rgba(255, 255, 255, 0.4);
      height: 0.7rem !important;
      width: 0.7rem !important;
      margin: 0.32rem !important;
    }
  }
}

//@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&display=swap');

@font-face {
  font-family: Futura;
  src: url('../assets/fonts/Futura-Book.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Futura;
  src: url('../assets/fonts/Futura-Heavy.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Futura;
  src: url('../assets/fonts/Futura-Light.otf');
  font-weight: 200;
}

@font-face {
  font-family: Times;
  src: url('../assets/fonts/Times.ttf');
  font-weight: 400;
}
@font-face {
  font-family: Times;
  src: url('../assets/fonts/Times-Bold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: Radikal;
  src: url('../assets/fonts/Radikal-Bold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: Radikal;
  src: url('../assets/fonts/Radikal-Regular.ttf');
  font-weight: 400;
}

@import 'styles/colors';
@import 'styles/variables';
@import 'styles/fontfaces';

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0px !important;
}

a {
  color: unset;
  text-decoration: unset;
}

.genericCard {
  border-radius: 2.8rem;
  box-shadow: 3px 3px 20px 5px rgba(0, 0, 0, 0.43);
}

.darkCard {
  background-color: $DARK_BLUE;
}
.lightBluCard {
  background-color: $LIGHT_BLUE;
}
.lightCard {
  background-color: $LIGHT_GREY;
}

.light {
  font-weight: 200 !important;
}
.normal {
  font-weight: 400 !important;
}
.bold {
  font-weight: 600 !important;
}

.textCenter {
  text-align: center;
}
